import { gql } from 'graphql-request'

const getTranslation = gql`
  query getTranslation($key: String!, $lang: Locale!) {
    translation(where: { key: $key }, locales: [$lang]) {
      text
    }
  }
`
export default getTranslation
