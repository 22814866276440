import { gql } from 'graphql-request'

const getSearchResults = gql`
  query getSearchResults(
    $search: String
    $lang: [Locale!]!
    $assetLocales: [Locale!]!
  ) {
    articles(where: { _search: $search }, locales: $lang) {
      id
      title
      slug
      isHearingStory
      tags {
        id
        title
      }
      topics {
        title
        slug
      }
      updatedAt
      image(locales: $assetLocales) {
        src: url(
          transformation: {
            image: { resize: { width: 90, height: 90, fit: crop } }
          }
        )
        src_webp: url(
          transformation: {
            image: { resize: { width: 90, height: 90, fit: crop } }
            document: { output: { format: webp } }
          }
        )
        alt
      }
    }
  }
`
export default getSearchResults
