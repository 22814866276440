import { API } from 'aws-amplify'

export default {
  props: {
    passedReactions: {
      type: Number,
      default: 0,
    },
    id: {
      type: String,
      required: true,
    },
    readingTime: {
      type: Number,
      default: 1,
    },
  },

  data() {
    return {
      reactions: 0,
    }
  },

  watch: {
    passedReactions(newValue) {
      this.reactions = newValue
    },
  },

  async fetch() {
    const { data } = await API.post('api', '/activityFeatures/getPostLikes', {
      body: {
        postId: this.id,
      },
    })
    this.reactions = data?.length ?? 0
  },

  computed: {
    stats() {
      const singularReaction = this.reactions === 1 ? '.singular' : ''
      const singularReadingTime = this.readingTime === 1 ? '.singular' : ''
      return [
        {
          name: this.$translation('stats.reactions.label'),
          value: this.$translation('stats.reactions' + singularReaction, {
            length: this.reactions ?? 0,
          }),
          icon: 'heart',
        },
        {
          name: this.$translation('stats.readingTime.label'),
          value: this.$translation('stats.readingTime' + singularReadingTime, {
            length: this.readingTime ?? 1,
          }),
          icon: 'time',
        },
      ]
    },
  },
}
