import browserSupport from '~/utils/browserSupport'

export default {
  computed: {
    theme() {
      return this.$store.state.global.theme
    },
  },
  watch: {
    '$i18n.locale'() {
      this.$fetch()
    },
    theme(newValue) {
      this.setTheme()
    },
  },
  mounted() {
    this.setTheme()

    if ('ontouchstart' in document.documentElement) {
      document.body.classList.add('is-touch')
    }
  },

  destroyed() {
    document.body.classList.remove('is-touch')
  },

  methods: {
    backgroundImage(image) {
      if (image && image.src) {
        const imageUrl = !browserSupport.webp
          ? image.src.replace('output=format:webp', 'output=format:jpg')
          : image.src
        return `background-image: url(${imageUrl})`
      }
    },

    setTheme() {
      const themesArray = Object.entries(
        this.$store.state.global.themes[this.theme] ??
          this.$store.state.global.themes.red
      )

      for (const [prop, value] of themesArray) {
        document.body.style.setProperty('--theme-' + prop, value)
      }
    },
  },
}
