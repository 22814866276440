import { gql } from 'graphql-request'

const getMenusWithArea = gql`
  query getMenusWithArea($area: [Area!], $lang: [Locale!]!) {
    sectionMenus(where: { area_contains_some: $area }, locales: $lang) {
      id
      label
      menuText
      menuIcon
      menuItems {
        ... on Link {
          id
          url
          title
          linkType: type
          type: __typename
          icon
          linkTarget
        }
        ... on Page {
          id
          url: slug
          title
          type: __typename
          icon
        }
        ... on ArticleTopic {
          id
          title
          url: slug
          type: __typename
        }
      }
      subMenu {
        ... on Link {
          id
          url
          title
          linkType: type
        }
        ... on Page {
          id
          url: slug
          title
          type: __typename
        }
        ... on ArticleTopic {
          id
          title
          url: slug
          type: __typename
        }
      }
      page {
        slug
        themeColor
      }
      priority
    }
  }
`
export default getMenusWithArea
