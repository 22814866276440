function prepareMsd(instance) {
  if (!instance.buildMsd) {
    throw new Error('buildMsd method not defined on component')
  }
  instance.msDynamics = instance.buildMsd()
}

export default {
  data() {
    return {
      msDynamics: null,
    }
  },
  mounted() {
    let timeout = null
    if (this.$store.state.msDynamics.ready) {
      // if the msd forms are ready
      // init the msDynamics instance
      prepareMsd(this)
    } else {
      // else wait for a bit and start
      // anyway. All form submissions will
      // result in an error state
      timeout = setTimeout(() => {
        this.msdTimeout = true
        prepareMsd(this)
      }, 2000)
    }

    // if the global msDynamics.ready-state
    // switches to true, we can safely init
    // the msDynamics instance
    this.$store.watch(
      (state) => state.msDynamics.ready,
      () => {
        prepareMsd(this)
        if (timeout) {
          clearTimeout(timeout)
        }
      }
    )
  },
}
