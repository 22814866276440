import { gql } from 'graphql-request'

const getSitemap = gql`
  query getLink($id: ID, $lang: [Locale!]!) {
    link(locales: $lang, where: { id: $id }) {
      id
      linkTarget
      title
      type
      icon
      url
    }
  }
`

export default getSitemap
