//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import layout from '~/mixins/layout'
import getTranslations from '~/gql/getTranslations'
export default {
  mixins: [layout],
  data() {
    return {
      userBranch: process.env.USER_BRANCH,
    }
  },
  async fetch() {
    // set translations
    this.$store.commit(
      'locale/setTranslations',
      await this.$graphcms.request(getTranslations, {
        lang: [this.$i18n.locale, this.$i18n.defaultLocale],
      })
    )

    // set default language translations
    this.$store.commit(
      'locale/setDefaultTranslations',
      await this.$graphcms.request(getTranslations, {
        lang: [this.$i18n.defaultLocale],
      })
    )

    // init resolverList
    await this.$localeResolver.init()
  },
  head() {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
    }
  },
  computed: {
    layout() {
      return this.$store.state.global.layout
    },
    headerVariant() {
      return this.layout === 'landingpage' ? 'tiny' : 'default'
    },
    headerHasHomeLink() {
      return !(this.layout === 'landingpage')
    },
    footerVariant() {
      return this.layout === 'landingpage' ? 'tiny' : 'default'
    },
    isLayoutLandingPage() {
      return this.layout === 'landingpage'
    },
  },
}
