//
//
//
//
//
//
//
//
//
//
//

export default {
  layout: 'error',
  props: {
    error: {
      type: Object,
      default() {
        return {
          statusCode: 404,
          message: 'noContent',
        }
      },
    },
  },

  computed: {
    title() {
      return this.$store.getters['locale/getTranslations'][this.error.message]
    },
  },
}
