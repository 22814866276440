import { gql } from 'graphql-request'

const getLocalesModel = gql`
  query getLocalesModel {
    localesModels {
      localeAcronym
      title
      url
      landingPage
      showInLanguageSwitch
      icon {
        url
      }
    }
  }
`
export default getLocalesModel
