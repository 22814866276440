import { gql } from 'graphql-request'

const getFormMentorsVariations = gql`
  query getFormMentorsVariations($id: ID!) {
    mentorsFormVariation: mentorsFormVariation(
      where: { id: $id }
      stage: PUBLISHED
    ) {
      id
      publishedAt
      updatedAt
      questionList(first: 50) {
        id
        localizations(
          includeCurrent: true
          locales: [
            de
            es_es
            en_gb
            pl
            nl
            it_it
            sv
            en_au
            da
            pt_br
            en_in
            no
            en
            cs
            sk
            sl
            ar
            fr
          ]
        ) {
          locale
          questionTitle
          questionsAnswers
        }
      }
      localizations(
        includeCurrent: true
        locales: [
          de
          es_es
          en_gb
          pl
          nl
          it_it
          sv
          en_au
          da
          pt_br
          en_in
          no
          en
          cs
          sk
          sl
          ar
          fr
        ]
      ) {
        locale
        updatedAt(variation: LOCALIZATION)
        formMentorsLocale
      }
    }
  }
`

export default getFormMentorsVariations
