//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Areas from '~/utils/areas'
import getSidebar from '~/gql/getSidebar'
import layout from '~/mixins/layout'
import user from '~/mixins/user'
import getTranslations from '~/gql/getTranslations'

export default {
  mixins: [layout, user],
  data() {
    return {
      userBranch: process.env.USER_BRANCH,
      sidebarUserSettings: [],
      sidebarDashboard: [],
      dashboardMenuItems: [],
    }
  },
  async fetch() {
    // set translations
    const translations = await this.$graphcms.request(getTranslations, {
      lang: [this.$i18n.locale, this.$i18n.defaultLocale],
    })
    this.$store.commit('locale/setTranslations', translations)

    // set default language translations
    this.$store.commit(
      'locale/setDefaultTranslations',
      await this.$graphcms.request(getTranslations, {
        lang: [this.$i18n.defaultLocale],
      })
    )

    // init resolverList
    await this.$localeResolver.init()

    // set sidebars
    const sidebars = await this.$graphcms.request(getSidebar, {
      area: [Areas.SIDEBAR_DASHBOARD, Areas.SIDEBAR_USERSETTINGS],
      lang: [this.$i18n.locale, this.$i18n.defaultLocale],
    })

    const sidebarItems = [
      ...sidebars?.sectionMenus,
      ...sidebars?.sections,
    ].sort((a, b) => a.priority - b.priority)
    const groupedSidebars = this.$groupBy(sidebarItems, 'area')

    this.sidebarDashboard = groupedSidebars[Areas.SIDEBAR_DASHBOARD]
    this.sidebarUserSettings = groupedSidebars[Areas.SIDEBAR_USERSETTINGS]

    // build sidebar menu
    this.dashboardMenuItems = this.sidebarDashboard.find((section) => {
      return section.type === 'SectionMenu'
    })?.menuItems
    /*
    const dashboardRoute = this.$router.options.routes.find((route) => {
      return false
     @todo add later
      const start =
        this.$i18n.locale === this.$i18n.defaultLocale
          ? '/dashboard'
          : '/' + this.$i18n.locale + '/dashboard'

      return route.meta.name === 'Dashboard' && route.path.startsWith(start)

    })
    this.dashboardMenuItems.unshift({

      url: this.localePath({
        path: dashboardRoute && dashboardRoute.path ? dashboardRoute.path : '',
      }),
      title: this.$translation('menu.' + dashboardRoute.meta.name),
      icon: dashboardRoute.meta.icon,

      })
    */

    // init resolverList
    await this.$localeResolver.init()
  },
  computed: {
    sidebar() {
      if (this.$route.meta.group === 'userSettings') {
        return this.sidebarUserSettings
      }
      if (this.$route.meta.name === 'Dashboard') {
        return this.sidebarDashboard
      }
      return null
    },
    menuItems() {
      if (this.$route.meta.group === 'userSettings') {
        return this.userSettingsMenuItems
      }
      if (this.$route.meta.name === 'Dashboard') {
        return this.dashboardMenuItems
      }
      return null
    },
  },
  mounted() {
    this.$store.commit('global/setTheme', 'red')
  },
}
