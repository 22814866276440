import { gql } from 'graphql-request'

const getHearingJourney = gql`
  query getHearingJourney(
    $id: ID
    $lang: [Locale!]!
    $assetLocales: [Locale!]
  ) {
    articles(where: { id: $id }, locales: $lang) {
      title
      content {
        html
        references {
          ... on Asset {
            id
            src: url(
              transformation: { image: { resize: { width: 752, fit: max } } }
            )
            src_webp: url(
              transformation: {
                image: { resize: { width: 752, fit: max } }
                document: { output: { format: webp } }
              }
            )
            src_large: url(
              transformation: { image: { resize: { width: 1270, fit: max } } }
            )
            src_large_webp: url(
              transformation: {
                image: { resize: { width: 1270, fit: max } }
                document: { output: { format: webp } }
              }
            )
            caption
            alt
            width
            height
            locale
          }
          ... on CallToAction {
            id
            title
            text
            type
            button
            link {
              ... on Link {
                url
              }
            }
          }
          ... on Gallery {
            id
            images(locales: $assetLocales) {
              src: url(
                transformation: {
                  image: { resize: { width: 370, height: 280, fit: crop } }
                }
              )
              src_large: url(
                transformation: {
                  image: { resize: { width: 600, height: 400, fit: crop } }
                }
              )
              src_webp: url(
                transformation: {
                  image: { resize: { width: 370, height: 280, fit: crop } }
                  document: { output: { format: webp } }
                }
              )
              src_large_webp: url(
                transformation: {
                  image: { resize: { width: 600, height: 400, fit: crop } }
                  document: { output: { format: webp } }
                }
              )
              alt
              caption
              locale
            }
          }
          ... on Quote {
            id
            quote
            author
          }
        }
      }
    }
  }
`

export default getHearingJourney
