import { gql } from 'graphql-request'

const getFooter = gql`
  query getFooter($area: [Area!], $lang: [Locale!]!) {
    sections(where: { area_contains_some: $area }, locales: $lang) {
      id
      headline
      displayHeadline
      priority
      type: __typename
      text: content {
        html
      }
    }
    sectionMenus(where: { area_contains_some: $area }, locales: $lang) {
      id
      label
      priority
      type: __typename
      menuItems {
        ... on Link {
          id
          url
          title
          type
        }
        ... on Page {
          id
          url: slug
          title
        }
      }
    }
  }
`
export default getFooter
