import { gql } from 'graphql-request'

const getSidebar = gql`
  query getSidebar($area: [Area!], $lang: [Locale!]!) {
    sections(
      where: { area_contains_some: $area }
      orderBy: priority_ASC
      locales: $lang
    ) {
      id
      headline
      displayHeadline
      priority
      area
      type: __typename
      text: content {
        html
      }
      link {
        ... on Link {
          url
          type: __typename
        }
        ... on Page {
          url: slug
          type: __typename
        }
      }
      linkText
    }
    sectionMenus(
      where: { area_contains_some: $area }
      orderBy: priority_ASC
      locales: $lang
    ) {
      id
      label
      priority
      area
      type: __typename
      menuItems {
        ... on Link {
          id
          url
          title
          icon
          type: __typename
        }
        ... on Page {
          id
          url: slug
          title
          icon
        }
      }
    }
  }
`
export default getSidebar
