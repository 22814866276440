export default {
  data() {
    return {
      navigator: null,
    }
  },

  mounted() {
    this.navigator = window.navigator
  },

  methods: {
    async share(title = null, url = null) {
      // set default share data
      const defaultUrl = process.env.baseUrl
        ? process.env.baseUrl + this.$route.path
        : this.$route.path
      const defaultTitle = this.$route.meta?.title

      // set share data
      const shareTitle =
        typeof title === 'string' ? title ?? defaultTitle : defaultTitle
      const shareUrl =
        typeof title === 'string' ? url ?? defaultUrl : defaultUrl

      // share via navigator
      if (this.navigator.share) {
        try {
          await this.navigator.share({ title: shareTitle, url: shareUrl })
        } catch (err) {}
      }
      // share fallback dialog
      else {
        this.$store.commit('global/setShare', {
          title: shareTitle,
          url: shareUrl,
        })
      }
    },
  },
}
