import { gql } from 'graphql-request'

const getTranslations = gql`
  query getTranslations($lang: [Locale!]!) {
    translations(locales: $lang, first: 1000) {
      key
      text
      locale
    }
  }
`
export default getTranslations
