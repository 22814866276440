import { gql } from 'graphql-request'

const getContentSlugs = gql`
  query getContentSlugs {
    pages(first: 1000) {
      localizations(includeCurrent: true) {
        locale
        slug
      }
    }
    articleTopics(first: 1000) {
      localizations(includeCurrent: true) {
        locale
        slug
      }
    }
    articles(first: 1000) {
      localizations(includeCurrent: true) {
        locale
        slug
      }
    }
  }
`
export default getContentSlugs
