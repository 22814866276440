import VRuntimeTemplate from 'v-runtime-template'

export default {
  components: {
    VRuntimeTemplate,
  },
  methods: {
    proseText(text, embeds, wrapper = true) {
      // parse gcm embeds to components
      const parsedContent = text.replace(
        /<(?:div|span)\s+(?:data-gcms-embed-type="(\w+)")\s+(?:data-gcms-embed-id="(\w+)")\s*(?:data-gcms-embed-(inline))?><\/(?:div|span)>/gi,
        function (match, p1, p2, p3) {
          const data = embeds.find((embed) => embed.id === p2)
          if (!data) return ''
          const variant = p3 ? `variant="${p3}"` : ''
          const escapedData = JSON.stringify(data)?.replaceAll("'", '&#39;')

          return `<article-embed type="${p1}" ${variant} data='${escapedData}' />`
        }
      )

      if (!wrapper) {
        return '<div class="prose">' + parsedContent + '</div>'
      }

      return (
        '<div class="wrapper wrapper--text prose">' + parsedContent + '</div>'
      )
    },
  },
}
